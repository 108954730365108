import MapCollection from "collections/map";
import Vue from "vue";
import franquiasService from "@/Services/FranquiasService";
import $handleError from "@/plugins/handleError";

const state = () => ({
  franquias: [],
  carregandoFranquias: false,
  franquiasLoaded: false,
  franquiasList: [],
});
const mutations = {
  setFranquias(state, franquias) {
    const franquiasMap = new MapCollection();
    franquias.map((franquia) => franquiasMap.set(franquia.id, franquia));
    // Vue.set(state, "franquias", franquiasMap);
    Vue.set(state, "franquiasLoaded", true);
    Vue.set(state, "franquiasList", Array.from(franquiasMap.values()));
    // state = {...state, franquias: franquiasMap, franquiasLoaded: true}
  },
  setFranquiaSelecionada(state, franquia) {
    state.franquiaSelecionada = franquia;
  },
  setFranquiasInfo(state, franquiasInfo) {
    Vue.set(state, "franquiasListInfo", franquiasInfo);
  },
  setCarregandoFranquias(state, newValue) {
    state.carregandoFranquias = newValue;
  },
  adicionarFranquia(state, franquia) {
    state.franquias.set(franquia.id, franquia);
  },
  atualizarFranquia(state, franquia) {
    state.franquias.set(franquia.id, franquia);
  },
  deletarFranquia(state, franquiaId) {
    state.franquias.delete(franquiaId);
  },
};
const actions = {
  async loadFranquias({ commit }, options = {}) {
    commit("setCarregandoFranquias", true);
    try {
      const franquiasList = await franquiasService.syncAll(options.force);
      // commit("setFranquias", franquiasList);
    } catch (error) {
      $handleError(error);
    } finally {
      commit("setCarregandoFranquias", false);
    }
  },
  async loadInfoFranquias({ commit }, options = {}) {
    commit("setCarregandoFranquias", true);
    try {
      const franquiasListInfo = await franquiasService.countInfoHome(options.force);
      commit("setFranquiasInfo", franquiasListInfo);
    } catch (error) {
      $handleError(error);
    }
    commit("setCarregandoFranquias", false);
  },
};
const getters = {
  franquias: (state) => state.franquias,
  carregandoFranquias: (state) => state.carregandoFranquias,
  franquiasLoaded: (state) => state.franquiasLoaded,
  franquiasList: (state) => state.franquiasList,
  franquiasListInfo: (state) => state.franquiasListInfo,
};
const namespaced = true;
export default {
  state,
  mutations,
  actions,
  getters,
  namespaced,
};
